import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

function importBuildTarget() {
  // DefinePlugin in webpack.config.js will substitute
  // process.env.REACT_APP_BUILD_TARGET with it's value at build time.
  // https://webpack.js.org/plugins/define-plugin/

  // TerserPlugin in webpack.config.js will eliminate dead code
  // ...if we make it easy enough (no maps or switches, etc).
  // https://webpack.js.org/plugins/terser-webpack-plugin/

  const target: string = process.env.REACT_APP_TARGET || '';
  switch (target) {
    case 'admin':
      return import("./admin/App");
    case 'office':
      return import("./office/App");
    case 'talent':
      return import("./talent/App");
    case 'user':
      return import("./user/App");
    default:
      return Promise.reject(
        new Error("No such build target: " + target)
      );
  }
}

// Import the entry point and render it's default export
void importBuildTarget().then(({ default: Target }) =>
  ReactDOM.render(
    <React.StrictMode>
      <Target />
    </React.StrictMode>,
    document.getElementById("root")
  )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
